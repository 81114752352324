/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { StyleSheetManager } from 'styled-components';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';

import { PageContext, LoadedCompContext } from '@marriott/mi-shop-components';
import { getDTTHeaders, pageInitialData, setSubDirectoryPrefix } from '@marriott/mi-shop-components/utils';
import { defineComponentMapping } from '../import-components';
import operationSignatures from '../_constants/operationSignatures.json';
import hotelQuickViewCacheConfig from '../_constants/hotelQuickViewCacheConfig.json';
import 'react-loading-skeleton/dist/skeleton.css';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { CustomModelClient, respGridUtil, canUseDOM, populatePIdataLayer } from '@marriott/mi-headless-utils';
import { useDCAPageModelStore } from '@marriott/mi-store-utils';
import { version as globalStylesVersion } from '@marriott/global-styles/package.json';
declare global {
  interface Window {
    jQuery: unknown;
    $: any;
    deployedEnvType: string;
    impressionArr: string[];
    impressionTrack: (value: string) => Record<string, string>;
    dataLayer?: Record<string, unknown>;
  }
}

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  // log.debug('[STEP 1] App component', Component, process.env);
  // log.debug('[STEP 1] App pageProps', pageProps);

  // log.debug('_app.tsx client side: ', props, pageProps);
  const rtlPluginTs = (...args: unknown[]) => {
    return rtlPlugin(args[0] as number, args[1] as string);
  };

  const {
    sessionData,
    SESSION_APP_GET_CALL_URL,
    SESSION_APP_POST_CALL_URL,
    resolvedUrl,
    requestId,
    currentLocale,
    getFeatureFlag,
    headersData,
    cookies,
    query,
    model,
    isAuthorMode,
    MI_DATALAYER_APP_URL,
    datalayerParams,
    datalayerProperties,
    NEXT_PUBLIC_PRE_PROCESSING_ADF_URL,
    interceptorResponse,
    NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
    sessionFetchedData, // session data fetched from productspecific call please maintain the same name
    NEXT_PUBLIC_DYNAMIC_ROUTING_URL_CLIENT,
    apolloEnvVars,
    GOOGLE_MAP_API_KEY,
    BAIDU_MAP_API_KEY,
    DEPLOYED_ENV_CONFIG,
    ENABLE_ADV_SEARCH_DATALAYER_CALL,
    NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
    GROUPS_DEV_URL,
    GROUPS_TEST_URL,
    isDTT,
    disableDistanceFromLocation,
    NEXT_PUBLIC_PRE_PROCESSING_URL,
    CACHE_CLEAR_URL,
    NEXT_PUBLIC_PREFIX,
    ENABLE_TARGET_SEGMENT_CALL,
    cnEnvVars,
    NEXT_GLOBAL_ASSET_PREFIX,
  } = pageProps;

  const preHeader: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-request-id': headersData ? headersData['x-request-id'] : '',
    Cookie: `sessionID=${cookies?.sessionID};UserIdToken=${cookies?.UserIdToken ? cookies?.UserIdToken : ''}`,
    'X-Host': headersData ? headersData['x-host'] : '',
  };
  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  setEnvVars({ ...apolloEnvVars, ...cnEnvVars });
  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['SESSION_GET_CALL_URL_CLIENT'] = pageProps?.SESSION_GET_CALL_URL_CLIENT;
  envVars['NEXT_PUBLIC_SUBMIT_SEARCH_URL'] = pageProps?.NEXT_PUBLIC_SUBMIT_SEARCH_URL;
  envVars['NEXT_PUBLIC_AVAILABILITY_SEARCH_URL'] = pageProps?.NEXT_PUBLIC_AVAILABILITY_SEARCH_URL;
  envVars['REQUEST_ID'] = requestId;
  envVars['ACCEPT_LANGUAGE'] = currentLocale?.replace('_', '-');
  envVars['DAC_IMG_DOMAIN'] = pageProps?.DAC_IMG_DOMAIN;
  envVars['GOOGLE_MAP_API_KEY'] = pageProps?.GOOGLE_MAP_API_KEY;
  envVars['NEXT_PUBLIC_PREFIX'] = pageProps?.NEXT_PUBLIC_PREFIX;
  envVars['SESSION_APP_POST_CALL_URL'] = pageProps?.SESSION_APP_POST_CALL_URL;
  envVars['SESSION_APP_POST_CALL_URL'] = pageProps?.SESSION_APP_POST_CALL_URL;
  envVars['BAIDU_MAP_API_KEY'] = pageProps?.BAIDU_MAP_API_KEY;
  setEnvVars(envVars);

  useEffect(() => {
    if (isAuthorMode) {
      let remoteURL: URL | string;
      try {
        remoteURL = new URL(document.body.dataset.remoteUrl || '');
        remoteURL = remoteURL.origin;
      } catch (e) {
        console.info('Handled remoteUrl for Non remote SPA author mode');
        remoteURL = '';
      }
      const assetUrl = `${
        process.env['NEXT_PUBLIC_ASSET_DOMAIN'] || remoteURL || 'https://www.marriott.com'
      }${NEXT_GLOBAL_ASSET_PREFIX}`;
      const globalStyle = document.createElement('link');

      globalStyle.rel = 'stylesheet';
      globalStyle.href =
        assetUrl +
        (model?.rtlEnable == 'true'
          ? `global-styles/${globalStylesVersion}/marriot.global.rtl.css`
          : `global-styles/${globalStylesVersion}/marriot.global.css`);
      document.head.appendChild(globalStyle);
    }
  }, []);

  const [sessionDataState, setSessionDataState] = useState<any>(sessionData);

  //This code will populate the PI data related datalayer elements on clientside
  if (canUseDOM) populatePIdataLayer();

  let subDirectoryContextPath = '';

  if (model) {
    subDirectoryContextPath = model.subDirectoryContextPath ? model.subDirectoryContextPath : '';
    setSubDirectoryPrefix(subDirectoryContextPath);
  }

  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode, currentLocale);
  const [pageState, setPageState] = useState<any>({ ...pageInitialData, authorMode: isAuthorMode });
  const [loadedCompState, setLaodedCompState] = useState<string[]>([]);

  const memoLoadedCompContextValue = useMemo(() => {
    const updateLoadedComponents = (componentName: string) => {
      if (!loadedCompState.includes(componentName)) {
        setLaodedCompState(preState => {
          return [...preState, componentName];
        });
      }
    };
    return { loadedCompState, updateLoadedComponents };
  }, [loadedCompState]);

  const dttHeaders = getDTTHeaders(headersData, query);

  const memoPageContexValue = useMemo(() => {
    const host = headersData?.['x-host'];
    const updateComponentState = ({
      componentName,
      newState,
    }: {
      componentName: string;
      newState: Record<string, any>;
    }) => {
      setPageState(preState => {
        return { ...preState, [componentName]: newState };
      });
    };

    const updatePageErrors = ({ componentName, errors }) => {
      setPageState(preState => {
        return {
          ...preState,
          pageLevelErrors: {
            ...preState.pageLevelErrors,
            [componentName]: errors,
          },
        };
      });
    };

    return {
      pageState,
      setPageState,
      updateComponentState,
      updatePageErrors,
      sessionData: Object.values(sessionFetchedData || {}).length > 0 ? sessionFetchedData : sessionDataState,
      setSessionData: setSessionDataState,
      resolvedUrl,
      getFeatureFlag,
      requestId,
      currentLocale: isAuthorMode ? 'en-US' : currentLocale?.replace('_', '-'),
      headersData,
      cookies,
      query,
      operationSignatures,
      hotelQuickViewCacheConfig,
      MI_DATALAYER_APP_URL,
      datalayerParams,
      datalayerProperties,
      NEXT_PUBLIC_PRE_PROCESSING_ADF_URL,
      NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
      interceptorResponse,
      preHeader,
      SESSION_APP_GET_CALL_URL: `https://${host}${SESSION_APP_GET_CALL_URL}`,
      SESSION_APP_POST_CALL_URL: `https://${host}${SESSION_APP_POST_CALL_URL}`,
      NEXT_PUBLIC_DYNAMIC_ROUTING_URL_CLIENT,
      GOOGLE_MAP_API_KEY,
      BAIDU_MAP_API_KEY,
      DEPLOYED_ENV_CONFIG,
      ENABLE_ADV_SEARCH_DATALAYER_CALL,
      NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
      GROUPS_DEV_URL,
      GROUPS_TEST_URL,
      isDTT,
      dttHeaders,
      disableDistanceFromLocation,
      NEXT_PUBLIC_PRE_PROCESSING_URL: `https://${host}${NEXT_PUBLIC_PRE_PROCESSING_URL}`,
      CACHE_CLEAR_URL: `https://${host}${CACHE_CLEAR_URL}`,
      NEXT_PUBLIC_PREFIX,
      ENABLE_TARGET_SEGMENT_CALL,
    };
  }, [
    pageState,
    setPageState,
    sessionData,
    sessionDataState,
    setSessionDataState,
    resolvedUrl,
    getFeatureFlag,
    requestId,
    currentLocale,
    headersData,
    cookies,
    query,
    operationSignatures,
    hotelQuickViewCacheConfig,
    MI_DATALAYER_APP_URL,
    datalayerParams,
    datalayerProperties,
    NEXT_PUBLIC_PRE_PROCESSING_ADF_URL,
    interceptorResponse,
    preHeader,
    sessionFetchedData,
    NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL,
    SESSION_APP_GET_CALL_URL,
    SESSION_APP_POST_CALL_URL,
    NEXT_PUBLIC_DYNAMIC_ROUTING_URL_CLIENT,
    GOOGLE_MAP_API_KEY,
    BAIDU_MAP_API_KEY,
    DEPLOYED_ENV_CONFIG,
    ENABLE_ADV_SEARCH_DATALAYER_CALL,
    NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
    GROUPS_DEV_URL,
    GROUPS_TEST_URL,
    isDTT,
    disableDistanceFromLocation,
    NEXT_PUBLIC_PRE_PROCESSING_URL,
    CACHE_CLEAR_URL,
    NEXT_PUBLIC_PREFIX,
    ENABLE_TARGET_SEGMENT_CALL,
    dttHeaders,
  ]);

  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const respGridModelClone = JSON.parse(JSON.stringify(respGridModel)); //To deep clone the model

  const setPageModel = useDCAPageModelStore(state => state.setPageModel);
  setPageModel(respGridModelClone);

  Object.defineProperty(rtlPluginTs, 'name', { value: 'rtlPluginMonorepo' });

  return (
    <LoadedCompContext.Provider value={memoLoadedCompContextValue}>
      <PageContext.Provider value={memoPageContexValue}>
        {/* Add loading Aria Label from AEM gloabl label */}
        {/* <ProgressLoaderProvider coverPage={true} loadingAriaLabel="Please wait, loading content."> */}
        <StyleSheetManager stylisPlugins={pageProps?.model?.rtlEnable === 'true' ? [rtlPluginTs] : []}>
          <Component {...pageProps} />
        </StyleSheetManager>
        {/* </ProgressLoaderProvider> */}
      </PageContext.Provider>
    </LoadedCompContext.Provider>
  );
};

export default App;
